import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
  useMediaQuery,
  Container,
  Hidden,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import IRSConerLinks from '../../components/irsconerlinks'
import FacebookIcon from '@material-ui/icons/Facebook'
import TwitterIcon from '@material-ui/icons/Twitter'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout'
import NewHeader from '../../components/NewHeader'
import SEO from '../../components/seo'
import Flag from '../../images/links.jpg'
import Firebase from '../../components/Firebase'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
const useStyles = makeStyles(theme => ({
  titleFont: {
    fontFamily: 'Volkhov',
    fontSize: 44,
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  marginMainTop: {
    marginTop: -150,
    marginBottom: 84,
  },
  marginIPADTop: {
    marginTop: -200,
    marginBottom: 84,
  },
  mt24: {
    marginTop: 24,
  },
  white: {
    color: '#fff',
  },
  paddingAll24: {
    padding: 18,
  },
}))

const pageTitle = 'Important Notification'
const ImportantNotification = props => {
  const matches = useMediaQuery('(max-width:1024px)')
  const classes = useStyles()
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    Firebase.firestore()
      .collection('notifications')
      .get()
      .then(function(data) {
        setLoading(false)
        data.forEach(function(doc) {
          setRows(old => [...old, doc.data()])
        })
      })
  }, [])
  return (
    <div>
      <Layout>
        <SEO title={pageTitle} />
        <div>
          <NewHeader title={pageTitle} image={Flag} />
          {matches ? (
            <div className={classes.marginIPADTop}>
              <Container>
                <Typography className={clsx(classes.titleFont, classes.white)}>
                  {pageTitle}
                </Typography>
                <Grid container spacing={2} className={classes.mt24}>
                  <Hidden xsDown mdDown>
                    <Grid item md={9} xs={12} sm={12}>
                      <Paper square>
                        <div
                          style={{ backgroundColor: '#3c5eab', padding: 6 }}
                        ></div>
                        <div className={classes.paddingAll24}>TEST</div>
                      </Paper>
                    </Grid>
                  </Hidden>
                  <Grid item md={12} xs={12} sm={12}>
                    <Paper square>
                      <div
                        style={{ backgroundColor: '#3c5eab', padding: 6 }}
                      ></div>
                      <div className={classes.paddingAll24}>
                        <Typography
                          variant="body1"
                          className={classes.mt24}
                          align="justify"
                        >
                          <div
                            dangerouslySetInnerHTML={{ __html: props.content }}
                          ></div>
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  <Hidden xsDown mdDown>
                    <Grid item md={3} xs={12}>
                      <Paper square>
                        <div
                          style={{ backgroundColor: '#b12229', padding: 6 }}
                        ></div>
                        <div className={classes.paddingAll24}></div>
                      </Paper>
                    </Grid>
                  </Hidden>
                </Grid>
              </Container>
            </div>
          ) : (
            ''
          )}
          <div className={classes.marginMainTop}>
            <Container>
              <Typography className={clsx(classes.titleFont, classes.white)}>
                {pageTitle}
              </Typography>
              <Grid container spacing={2} className={classes.mt24}>
                {matches ? (
                  ''
                ) : (
                  <Grid item md={9} xs={12} sm={12}>
                    <Paper square>
                      <div
                        style={{ backgroundColor: '#3c5eab', padding: 6 }}
                      ></div>
                      {loading ? (
                        <div className={classes.paddingAll24} align="center">
                          <CircularProgress className={classes.mt24} />
                          <Typography variant="h5" className={classes.mt24}>
                            Loading latest Notifications
                          </Typography>
                        </div>
                      ) : (
                        <div>
                          {rows.map((v, k) => (
                            <ExpansionPanel key={k}>
                              <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Typography className={classes.heading}>
                                  <b>{v.title}</b>
                                </Typography>
                              </ExpansionPanelSummary>
                              <ExpansionPanelDetails>
                                <div align="left">
                                  <Typography>{v.text}</Typography>
                                  <div style={{ marginTop: 35 }}>
                                    {typeof v.linkFirst !== 'undefined' ? (
                                      <Button
                                        href={v.linkFirst}
                                        target="_blank"
                                        style={{
                                          color: '#3c5eab',
                                          textTransform: 'none',
                                        }}
                                      >
                                        {v.linkFirst}
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                    &nbsp;&nbsp;&nbsp;
                                    {typeof v.linkSecond !== 'undefined' ? (
                                      <Button
                                        href={v.linkSecond}
                                        target="_blank"
                                        style={{
                                          color: '#3c5eab',
                                          textTransform: 'none',
                                        }}
                                      >
                                        {v.linkSecond}
                                      </Button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                  <div style={{ marginTop: 8 }}>
                                    <Grid container spacing={2}>
                                      {typeof v.twitter !== 'undefined' ? (
                                        <Grid item md={1}>
                                          <IconButton
                                            href={v.twitter}
                                            target="_blank"
                                          >
                                            <TwitterIcon />
                                          </IconButton>
                                        </Grid>
                                      ) : (
                                        ''
                                      )}
                                      {typeof v.facebook !== 'undefined' ? (
                                        <Grid item md={1}>
                                          <IconButton
                                            href={v.facebook}
                                            target="_blank"
                                          >
                                            <FacebookIcon />
                                          </IconButton>
                                        </Grid>
                                      ) : (
                                        ''
                                      )}
                                    </Grid>
                                  </div>
                                </div>
                              </ExpansionPanelDetails>
                            </ExpansionPanel>
                          ))}
                        </div>
                      )}
                    </Paper>
                  </Grid>
                )}

                <Hidden xsDown mdDown>
                  <Grid item md={3} xs={12}>
                    <Paper square>
                      <div
                        style={{ backgroundColor: '#b12229', padding: 6 }}
                      ></div>
                      <div className={classes.paddingAll24}>
                        <IRSConerLinks />
                      </div>
                    </Paper>
                  </Grid>
                </Hidden>
              </Grid>
            </Container>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default ImportantNotification
